import { AndroidService } from '@/service/Android';
import { StorageService } from '@/service/StorageService';
import axios from 'axios';
import { API_END_POINT } from '../config';
import {
  API_RETRIED_SUCCESS,
  BH_CASH_API_LATENCY_FE,
} from '@/constants/Events';
import { User } from '@/model/Users';
import { AuthService } from '@/service/AuthService';
import { makeAxiosRetry } from '@/utils/axios';

export const HEADERS_MAP = {
  APPN: 'appn',
  X_PROFILE: 'x-profile',
  X_LANGUAGE: 'x-language',
  X_DEVICE_ID: 'x-device-id',
  X_DEVICE_MODEL: 'x-device-model',
  X_DEBUG: 'x-debug',
  X_PN: 'x-pn',
  X_PLATFORM: 'x-platform',
  X_COUNTRY: 'x-country',
  AUTH_TOKEN: 'auth-token',
};

export const apiAxiosV2 = axios.create({
  baseURL: API_END_POINT + '/v2',
  timeout: 120000,
});
export const apiAxiosV1 = axios.create({
  baseURL: API_END_POINT + '/v1',
  timeout: 120000,
});
export const authAxiosV1 = axios.create({
  baseURL: API_END_POINT + '/v1',
  timeout: 120000,
});

makeAxiosRetry(apiAxiosV1);
makeAxiosRetry(apiAxiosV2);

const latencyReporter = captureAPILatency(report => {
  const { method, time, type, url, statusCode } = report;
  AndroidService.logEvent(BH_CASH_API_LATENCY_FE, {
    url,
    method,
    latency: time,
    type,
    statusCode,
  });
});

apiAxiosV2.interceptors.request.use(
  compose(latencyReporter.interceptRequest, addAccessTokenInterceptor)
);
apiAxiosV2.interceptors.response.use(
  compose(latencyReporter.interceptSuccessResponse, checkIfNetworkStillAlive),
  compose(latencyReporter.interceptErrorResponse, processAuthNetworkErrors)
);
apiAxiosV1.interceptors.request.use(
  compose(latencyReporter.interceptRequest, addAccessTokenInterceptor)
);
apiAxiosV1.interceptors.response.use(
  compose(latencyReporter.interceptSuccessResponse, checkIfNetworkStillAlive),
  compose(latencyReporter.interceptErrorResponse, processAuthNetworkErrors)
);

function checkIfNetworkStillAlive(data) {
  if (!window.navigator.onLine) {
    dispatchEvent(new Event('online'));
  }

  return data;
}

function addAccessTokenInterceptor(config) {
  const headers = config.headers || {};
  User.setProfileId();
  const accessToken = StorageService.getAccessToken();
  if (accessToken) headers[HEADERS_MAP.AUTH_TOKEN] = accessToken;
  headers[HEADERS_MAP.X_COUNTRY] = StorageService.getCountryCode();
  headers[HEADERS_MAP.APPN] = StorageService.get(HEADERS_MAP.APPN);
  headers[HEADERS_MAP.X_PROFILE] = StorageService.get(HEADERS_MAP.X_PROFILE);
  headers[HEADERS_MAP.X_LANGUAGE] =
    StorageService.getLanguage() ||
    StorageService.get(HEADERS_MAP.X_LANGUAGE) ||
    'ENGLISH';
  headers[HEADERS_MAP.X_DEVICE_ID] = StorageService.get(
    HEADERS_MAP.X_DEVICE_ID
  );
  headers[HEADERS_MAP.X_DEVICE_MODEL] = StorageService.get(
    HEADERS_MAP.X_DEVICE_MODEL
  );
  headers[HEADERS_MAP.X_DEBUG] =
    StorageService.get(HEADERS_MAP.X_DEBUG) ?? false;
  headers[HEADERS_MAP.X_PN] = process.env.NEXT_PUBLIC_PACKAGE_NAME;
  (headers[HEADERS_MAP.X_PLATFORM] = 'web'), // StorageService.get(HEADERS_MAP.X_PLATFORM);
    (config.headers = headers);
  return config;
}
// const profileId =
// '70820f9c-d6b4-4158-9bf3-932e2b3a19ed' ||
// StorageService.get(HEADERS_MAP.X_PROFILE);
// if (profileId) headers['x-profile'] = profileId;
// headers['x-pn'] = 'com.sikka.freemoney.dev';
// headers['appn'] = '64';
// headers['x-platform'] = 'android';
// headers['x-country'] = StorageService.getCountryCode() || 'IN';
// headers['x-language'] = 'ENGLISH';
// headers['x-device-id'] = '5f348c56-7095-4737-9a2f-046eb58f24f1'; //|| deviceModel;
// (headers['x-device-model'] = 'Google-google-Pixel 6a'), //|| deviceModel;

function isTokenExpired(error) {
  if (!error.config) return false;
  const isTokenExpired =
    error.response?.status === 401 ||
    error.response?.data.error === 'Authentication failed';
  return isTokenExpired;
}

async function processAuthNetworkErrors(err) {
  if (handleHttpErrors(err)) {
    return;
  }

  const shouldRefreshAccessToken = isTokenExpired(err);

  if (shouldRefreshAccessToken) {
    try {
      const token = await getNewAuthAccessToken();
      err.config.headers['auth-token'] = token;
      return new Promise(resolve => {
        setTimeout(() => {
          err.config.__retryCount = (err.config.__retryCount || 0) + 1;
          return resolve(axios(err.config));
        }, 1000);
      });
    } catch (err) {
      handleHttpErrors(err);
      throw new Error('failed to refresh tokens');
    }
  } else {
    return Promise.reject(err);
  }
}

const getNewAuthAccessToken = withProtectedTask(async () => {
  try {
    const res = await authAxiosV1.post('/user/token/refresh', {
      refresh_token: StorageService.getAuthRefreshToken(),
    });
    StorageService.setAccessToken(res.data.access_token);
    StorageService.setAuthRefreshToken(
      res.data.refresh_token || StorageService.getAuthRefreshToken()
    );
    return res.data.access_token;
  } catch (err) {
    logoutBannedUser(err);
    StorageService.setAccessToken(null);
    StorageService.setAuthRefreshToken(null);
    return;
  }
});

function withProtectedTask(fn) {
  const cachedPromise = {};

  return function wrapper() {
    const hasArgs = !!arguments.length;
    const hash = arguments.length ? getObjectHash(arguments) : '__DEFAULT__';

    if (hasArgs) {
      console.warn(
        '[unsafe_withProtectedTask] Be careful if you are willing to use in production...'
      );
    }

    if (cachedPromise[hash]) {
      return cachedPromise[hash];
    }

    cachedPromise[hash] = fn
      .apply(null, arguments)
      .then(data => {
        cachedPromise[hash] = null;
        return data;
      })
      .catch(err => {
        cachedPromise[hash] = null;
        return Promise.reject(err);
      });

    return cachedPromise[hash];
  };
}

function getObjectHash(obj) {
  //bechmark: https://codesandbox.io/s/getobjecthash-m78qb?file=/src/index.js
  return JSON.stringify(obj);
}

function captureAPILatency(reporter) {
  function interceptRequest(config) {
    if (!config.__extra) {
      config.__extra = {};
    }

    config.__extra.requestStart = Date.now();

    return config;
  }

  function interceptSuccessResponse(res) {
    if (!res.config.__extra) {
      res.config.__extra = {};
    }
    res.config.__extra.requestEnd = Date.now();

    if (res.config.isRetried) {
      if (window.retriedRequestsMap.hasRequest(res.config.url)) {
        const retriedRequest = window.retriedRequestsMap.popRequest(
          res.config.url
        );

        AndroidService.logEvent(API_RETRIED_SUCCESS, {
          api: retriedRequest.fullUrl,
          no_of_retries: retriedRequest.retryCount,
        });
      }
    }
    doReport({
      reportType: 'ok',
      config: res.config,
      statusCode: res.status || -1,
    });

    return res;
  }

  function interceptErrorResponse(err) {
    if (axios.isCancel(err)) {
      return;
    }

    if (err.config && !err.config?.__extra) {
      err.config.__extra = {};
    }
    if (err.config?.__extra) {
      err.config.__extra.requestEnd = Date.now();
    }

    doReport({
      reportType: 'error',
      config: err.config,
      statusCode: err.status || err?.response?.status || -1,
    });

    return err;
  }

  function getQueryString(config) {
    let params = '';
    let i = 0;

    for (let param in config.params) {
      if (i != 0) {
        params += `&${param}=${config.params[param]}`;
      } else {
        params = `?${param}=${config.params[param]}`;
      }

      i++;
    }

    return params;
  }

  function getBuiltURL(config) {
    let url = config.url;

    if (getQueryString(config) != '') {
      url =
        url.charAt(url.length - 1) == '/' ? url.substr(0, url.length - 1) : url;
      url += getQueryString(config);
    }

    return url.trim();
  }

  function doReport({ config, reportType, statusCode }) {
    const requestEnd = config.__extra?.requestEnd || 0;
    const requestStart = config.__extra?.requestStart || 0;
    reportType = reportType || 'unknown';

    const url = getBuiltURL(config);
    reporter({
      time: requestEnd - requestStart,
      url,
      method: config.method,
      type: reportType,
      statusCode,
    });
  }

  return {
    interceptRequest,
    interceptSuccessResponse,
    interceptErrorResponse,
  };
}

function compose(...fns) {
  return async function handler() {
    let op = undefined;
    for (let i = 0; i < fns.length; i++) {
      op = await fns[i].apply(null, arguments);
    }
    return op;
  };
}

function handleHttpErrors(err) {
  const isBanned = err?.response?.data?.err_code === 1004;
  if (isBanned) {
    logoutBannedUser();
  }
  const isSessionExpired = err?.response?.status === 403;
  if (isSessionExpired) {
    logoutSessionExpired(
      err.config.url === '/user/google_auth/login'
        ? `You are banned, please contact at ${process.env.NEXT_PUBLIC_SUPPORT_MAIL}`
        : 'Session expired. Please log in again.'
    );
  }

  return isBanned || isSessionExpired;
}

function logoutSessionExpired(message) {
  AndroidService.showToast(message);
  AuthService.logout();
  window.location.href = '/login';
}

function logoutBannedUser() {
  AuthService.logout();
  window.location.href = '/login?redirection_reason=banned';
}
